import React from "react";
import { notification, Radio } from 'antd';

export default function StepTwo({...props}) {
    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type) => {
        api[type]({
            message: 'Valori confermati con successo',
            duration:3,
        });
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        props.next()
        openNotificationWithIcon('success')
    }

    return (
        <>
            {contextHolder}
            <div className='mt-9'/>
            <form onSubmit={handleSubmit} className='flex flex-col'>
                <br/><br/>
                <Radio.Group
                    onChange={e => props.setMuffa(e.target.value)}
                    value={props.muffa}
                >
                    <Radio className='mx-4' value={true}>Presenza muffa Si</Radio>

                    <Radio className='mx-4' value={false}>Presenza muffa No</Radio>
                </Radio.Group>

                <br/><br/>
                <Radio.Group
                    onChange={e => props.setCrepe(e.target.value)}
                    value={props.crepe}
                >
                    <Radio className='mx-4' value={true}>Presenza Crepe Si</Radio>

                    <Radio className='mx-4' value={false}>Presenza Crepe No</Radio>
                </Radio.Group>
                <br/><br/>
                <input className='bg-green-500 text-white p-2 rounded cursor-pointer' type="submit" value="Avanti"/>
            </form>
        </>
    )
}
