import React, {useState} from 'react';
import {Modal} from 'antd';
import {Button, Steps, Carousel, theme} from 'antd';

import logowa from './assets/wa.jpg'
import vitto from './assets/vitto.jpeg'
import video from './assets/bgvideo.mp4'
import Step from "./components/Step";
import StepTwo from "./components/StepTwo";
import StepThree from "./components/StepThree";

import work1 from './assets/lavori/lavori1.jpg'
import work2 from './assets/lavori/lavori2.jpg'
import work3 from './assets/lavori/lavori3.jpg'

import work6 from './assets/lavori/lavori6.jpg'
import work7 from './assets/lavori/lavori7.jpg'
import work8 from './assets/lavori/lavori8.jpg'
import work9 from './assets/lavori/lavori9.jpg'

import work13 from './assets/lavori/lavori13.jpg'
import work14 from './assets/lavori/lavori14.jpg'
import work15 from './assets/lavori/lavori15.jpg'
import work16 from './assets/lavori/lavori16.jpg'
import work17 from './assets/lavori/lavori17.jpg'

const workArray = [work1, work2, work3, work6, work7, work8, work9, work13, work14, work15, work16, work17]


export default function App() {
    const {token} = theme.useToken();
    const [current, setCurrent] = useState(0);
    const [open, setOpen] = useState(false);
    const [lun, setLun] = useState("");
    const [lar, setLar] = useState("");
    const [alt, setAlt] = useState("");
    const [color, setColor] = useState(true);
    const [crepe, setCrepe] = useState(false);
    const [muffa, setMuffa] = useState(false);

    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };

    const steps = [
        {
            title: 'Step 1',
            content: <Step lun={lun} setLun={setLun}
                           lar={lar} setLar={setLar}
                           alt={alt} setAlt={setAlt}
                           color={color} setColor={setColor}
                           next={next}

            />,
        },
        {
            title: 'Step 2',
            content: <StepTwo muffa={muffa} setMuffa={setMuffa}
                              crepe={crepe} setCrepe={setCrepe}
                              next={next}
            />,
        },
        {
            title: 'Step 3',
            content: <StepThree lun={lun} lar={lar} alt={alt}
                                color={color} crepe={crepe}
                                muffa={muffa}
                                next={next}
            />,
        },
    ];

    const items = steps.map((item) => ({key: item.title, title: item.title}));
    const contentStyle = {
        textAlign: 'center',
        backgroundColor: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        marginTop: 16,
    };

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
        setAlt('');
        setLar('');
        setLun('');
        setColor(true);
        setCrepe(false);
        setMuffa(false)
        setCurrent(0)
    };


    return (
        <div>
            <main>
                <header className='bg-white flex justify-center items-center pt-24'>
                    <video className='w-full' id="background-video"
                           autoPlay={true} playsInline={true} muted={true} controls={false} loop={true}>
                        <source src={video} type="video/mp4"/>
                    </video>

                </header>


                <div className='py-28'>
                    <div className="w-11/12 lg:w-2/3 mx-auto">
                        <p className='text-3xl font-bold uppercase'>
                            servizi
                        </p>
                        <br/>
                        <br/>
                        <p className='text-xl leading-loose tracking-wide font-light' data-aos="fade-up"
                           data-aos-duration="1000">
                            Un team di professionisti affidabili ed esperti per dare una nuova vita
                            alle pareti della vostra casa o del vostro ufficio, siete nel posto giusto. Siamo
                            orgogliosi
                            di presentarci come una squadra di imbianchini qualificati e appassionati, pronti a
                            rendere
                            i vostri ambienti più belli e accoglienti con la nostra arte {'dell\'imbiancatura.'}
                        </p>
                    </div>
                    <section className='container mx-auto bg-white grid grid-cols-1 lg:grid-cols-3 gap-20 pt-40'
                             id='services'>
                        <div className='flex flex-col items-center'>
                            <span className="material-symbols-outlined bg-blue-200 p-3 rounded-full">
                                      imagesearch_roller
                            </span>
                            <br/>
                            <p className='uppercase tracking-wide px-3'>Tinteggiatura</p>
                        </div>
                        <div className='flex flex-col items-center'>
                            <span className="material-symbols-outlined bg-amber-200 p-3 rounded-full">
                                        brush
                            </span>
                            <br/>
                            <p className='uppercase tracking-wide  px-3'>Finiture Interni Decorative</p>
                        </div>
                        <div className='flex flex-col items-center'>
                            <span className="material-symbols-outlined bg-lime-200 p-3 rounded-full">
                                         format_paint
                            </span>
                            <br/>
                            <p className='uppercase tracking-wide  px-3'>Verniciatura</p>
                        </div>

                        <div className='flex flex-col items-center'>
                            <span className="material-symbols-outlined bg-green-200 p-3 rounded-full">
                                         open_in_new
                            </span>
                            <br/>
                            <p className='uppercase tracking-wide  px-3'>Cartongesso</p>
                        </div>
                        <div className='flex flex-col items-center'>
                            <span className="material-symbols-outlined bg-gray-100 p-3 rounded-full">
                                         colors
                            </span>
                            <br/>
                            <p className='uppercase tracking-wide  px-3'>Imbiancatura</p>
                        </div>
                        <div className='flex flex-col items-center'>
                            <span className="material-symbols-outlined bg-purple-200 p-3 rounded-full">
                                         ink_eraser
                            </span>
                            <br/>
                            <p className='uppercase tracking-wide  px-3'>Airless</p>
                        </div>
                    </section>
                </div>

                <section>
                    <div className='flex justify-center items-center my-40' id='preventivo'>
                        <div onClick={showModal} className='cursor-pointer'>
                            <div style={{height: '86vh'}}
                                 className='neu flex w-screen justify-center items-center px-9'>
                                <div className="w-11/12 lg:w-1/2 mx-auto text-center">
                                    <h2 className='text-3xl lg:text-5xl font-light text-white uppercase underline underline-offset-8'>Calcola
                                        preventivo in 3 step</h2>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Modal
                        title="Calcola Preventivo"
                        open={open}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={[]}
                        centered
                    >
                        <Steps className='py-5' current={current} items={items}/>
                        <div style={contentStyle}>{steps[current].content}</div>
                        <div className='flex flex-col pt-9 text-xs'>
                            <br/>
                            {current > 0 && (
                                <Button style={{margin: '0 8px'}} onClick={() => prev()}>
                                    Indietro
                                </Button>
                            )}
                            <br/>
                            * Il calcolo del preventivo online è indicativo. Per un Preventivo più accurato servirà un sopralluogo in loco.
                        </div>
                    </Modal>
                </section>

                <section className='w-11/12 lg:w-2/3 mx-auto flex flex-col px-0 lg:px-20' id='me'>


                    <p className='text-3xl font-bold uppercase'>
                        Chi Sono
                    </p>

                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-20 py-12'>
                        <div>
                            <img src={vitto} alt={'ig'} className='w-full rounded-xl'/>
                        </div>
                        <div className='text-left flex items-center' data-aos="fade-up" data-aos-duration="2000">
                            <p className=' leading-loose tracking-wide'>
                                Sono Vittorio Mongiardo, creatore e proprietario di <strong>Live in Colors</strong>.
                                Imbianchino di
                                seconda generazione. Intraprendo questa carriera da mio padre, artigiano da oltre 50
                                anni.
                                In questi anni ho fatto esperienze anche fuori {'dall\'Italia'} per imparare nuove
                                tecniche e
                                approfondire la mia conoscenza nel settore. Live in Colors nasce a Milano con lo scopo
                                di
                                migliorare gli ambienti e rendere piu accogliente la vostra casa, per voi e per i vostri
                                ospiti.
                            </p>
                        </div>
                    </div>


                </section>

                <section className='w-11/12 lg:w-2/3 mx-auto flex flex-col px-0 lg:px-20 pt-20 md:pt-40' data-aos="fade-up" data-aos-duration="2000">
                    <Carousel autoplay>
                        {workArray.map((i, index) => (
                            <img
                                src={i}
                                key={index}
                                alt="Author"
                                className='rounded-xl h-64 md:h-96 lg:h-[28rem] xl:h-[38rem] min-[2000px]:h-[48rem] object-cover'
                            />
                        ))}
                    </Carousel>
                </section>

                <section>
                    <div className=' grid grid-cols-1 md:grid-cols-3 container mx-auto items-center gap-20 py-52'>
                        <div
                            className='flex flex-col text-center'>
                            <div>
                                <span className="material-symbols-outlined bg-zinc-100 p-4 rounded-full">task</span>
                            </div>
                            <p className='text-xl py-9 font-bold'>
                                Servizi
                            </p>
                            <p>
                                Rinnova le tue pareti con eleganza e stile
                            </p>
                        </div>
                        <div
                            className='flex flex-col text-center'>
                            <div>
                                <span className="material-symbols-outlined bg-zinc-100 p-4 rounded-full">
                                    location_on
                                </span>
                            </div>
                            <p className='text-xl py-9 font-bold'>
                                Preventivo Gratuito
                            </p>
                            <p>
                                Comodità e la convenienza col sopralluogo gratuito
                            </p>
                        </div>
                        <div
                            className='flex flex-col text-center'>
                            <div>
                                <span className="material-symbols-outlined bg-zinc-100 p-4 rounded-full">mail</span>
                            </div>
                            <p className='text-xl py-9 font-bold'>
                                Contattami
                            </p>
                            <p>
                                Ricevi un preventivo gratuito e senza impegno
                            </p>
                        </div>
                    </div>

                </section>
            </main>
            <a
                href="https://wa.me/3345052756"
                className="whatsapp_float fixed z-50 bottom-9 right-9"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={logowa} alt='logowa' className='w-20'/>
            </a>
        </div>
    )
}

