import React, {useEffect, useState} from 'react';

export default function StepThree({...props}) {
    const [preventivo, setPreventivo] = useState(0)


    const {lun, lar, alt, color, crepe, muffa} = props

    const area = lun * lar
    const mt2 = area * alt

    const totalSquared = mt2 + area
    // color is inverted true is white
    const totalWithTint = color ? totalSquared * 8 : totalSquared * 10
    const totalAddMuffa = muffa ? totalWithTint + 50 : totalWithTint
    const totalWithCrepe = crepe ? totalAddMuffa + 50 : totalAddMuffa


    useEffect(() => {
        setPreventivo(totalWithCrepe)
    }, [totalWithCrepe]);

    return (
        <div className='flex flex-col'>
            <br/>
            {preventivo ?
                <h4 className='text-4xl'>€ {preventivo}</h4>
                :
                null
            }
            <br/>
        </div>
    )
}
