import logo from "../assets/logo 13.28.09.jpg";
import React from "react";

export default function Banner(){
    return(
        <div className='w-full flex flex-col justify-center items-center z-10 fixed'>
            <div className='w-full p-3 bg-black'>
                <div className='mx-auto text-center text-gray-50'>
                    <a href="#preventivo">
                        ❗️Calcola il tuo preventivo online❗
                    </a>
                </div>
            </div>
            <div className='w-full bg-white flex justify-between border-b border-zinc-200 p-2'>
                <div>
                    <img src={logo} alt='logo' className='w-24 rounded-xl'/>
                </div>
                <div className='hidden lg:flex items-center'>

                </div>
                <div className='flex items-center'>
                    <a
                        href="mailto:info@liveincolors.it" target="_blank" rel="noopener noreferrer">
                        <p className="prenota"> info@liveincolors.it</p>
                    </a>
                </div>
            </div>
        </div>

    )
}
