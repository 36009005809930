import React from "react";
import { notification, Radio } from 'antd';

export default function Step({...props}) {
    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type) => {
        api[type]({
            message: 'Valori confermati con successo',
            duration:3,
        });
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        props.next()
        openNotificationWithIcon('success')
    }

    return (
        <>
            {contextHolder}
            <div className='mt-9'/>
            <form onSubmit={handleSubmit} className='flex flex-col'>

                <input
                    className='border p-2 rounded'
                    type="text"
                    value={props.lun}
                    onChange={e => props.setLun(e.target.value)}
                    placeholder='Lunghezza pareti Metri'
                    required
                />
                <br/>
                <input
                    className='border p-2 rounded'
                    type="text"
                    value={props.lar}
                    onChange={e => props.setLar(e.target.value)}
                    placeholder='Larghezza pareti Metri'
                    required
                />
                <br/>
                <input
                    className='border p-2 rounded'
                    type="text"
                    value={props.alt}
                    onChange={e => props.setAlt(e.target.value)}
                    placeholder='Altezza pareti Metri'
                    required
                />
                <br/><br/>
                <Radio.Group
                    onChange={e => props.setColor(e.target.value)}
                    value={props.color}
                >
                    <Radio className='mx-4' value={true}>Pittura Bianca</Radio>
                    <Radio className='mx-4' value={false}>Pittura Colorata</Radio>
                </Radio.Group>

                <br/><br/>

                <input className='bg-green-500 text-white p-2 rounded cursor-pointer' type="submit" value="Avanti"/>
            </form>
        </>
    )
}
